import DashboardGrid from './DashboardGrid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Loader from 'components/common/loader/Loader';
import GridFilters from 'components/claims/filters/GridFilters';
import NotesModal from 'components/events/notes/NotesModal';
import EventsTable from 'components/claims/table/EventsTable';
import {
  IFiltersContext,
  FiltersContext,
} from 'context/filters/FiltersContext';
import { addDays } from 'date-fns';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { EVENT_STATUSES } from 'static/constants/event-status';
import { ActionType } from 'types/action';
import { Event } from 'types/event';
import { Option } from 'types/option';
import './styles.scss';
import {
  IInspectionContext,
  InspectionContext,
  getNotes,
  saveNote,
  getInspections,
  setLoading,
  fetchAgents,
  resendIntegration,
  transferEstimateCSM,
} from 'context/inspections';
import { IUserContext, UserContext } from 'context/user';
import { userActionsPermissions } from 'shared/utils';
import { BrandsId, CountryCode, ResourceAction, Rol } from 'types/common';
import { IAppFormType } from 'types/user';
import useDevice from 'shared/hooks/useDevice';
import SearchError from 'components/common/search-error/SearchError';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from '@mui/material';
import { addMonths } from 'date-fns/esm';
import userIcon from '../../../assets/icons/user.svg';
import { StyleContext } from 'context/style/style-context';
import { gridColumnVisibilityModelSelector } from '@mui/x-data-grid';
import { FilterState } from 'types/filter-state';

interface StatusIndicator {
  statusName: string;
  currentRows: number;
}

export default function Events(): JSX.Element {
  const history = useHistory();

  //Context
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const { queryFilters, searchQuery, setQueryFilters } = useContext<
    IFiltersContext
  >(FiltersContext);
  const {
    state: { currentUser },
    dispatch: userDispatch,
  } = useContext(UserContext) as IUserContext;
  const { updateParentStyle } = useContext(StyleContext);

  // State
  const [selectedEventNote, setSelectedEventNote] = useState<Event | null>(
    null,
  );
  const [isNoteVisible, setIsNoteVisible] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<number | undefined>(
    queryFilters.statusId,
  );
  const [showMyServices, setShowMyServices] = useState(false);
  // States for filters
  const [company, setCompany] = React.useState<Option | null>(null);
  const [stageIdFilter, setStageIdFilter] = React.useState<any>(
    queryFilters?.statusId,
  );
  const [inspector, setInspector] = React.useState<Option | null>(null);
  const [agent, setAgent] = React.useState<Option | null>(null);
  const [state, setState] = React.useState<Option<FilterState, string> | null>(
    null,
  );
  const [tag, setTag] = React.useState<Option | null>(null);
  const [expressAdjust, setExpressAdjust] = React.useState<
    boolean | null | undefined
  >(Boolean(queryFilters?.filterByExpressAdjust));
  const [aapIndicator, setAapIndicator] = React.useState<
    boolean | null | undefined
  >(Boolean(queryFilters?.filterByAAP));
  const [pendingSinisters, setPendingSinisters] = React.useState<
    boolean | null | undefined
  >(Boolean(queryFilters?.filterText === 'QUALITAS-GET-SINISTERS'));
  const { isMobile } = useDevice();

  const countryCode = localStorage.getItem('countryCode') || ''; //To use as a trigger to fetch inspections whenever the value changes.
  const isSURAEvent = selectedEventNote?.companyId === BrandsId.SURA;
  let assignedEvents = 0;
  inspectionState.statusesIndicators
    .filter((i) => ['Creado', 'Completado'].includes(i.statusName))
    .forEach((a) => (assignedEvents += a.currentRows));

  useEffect((): void => {
    if (selectedEventNote) {
      setIsNoteVisible(true);
    }
  }, [selectedEventNote]);

  useEffect((): void => {
    getInspections({ ...queryFilters, showMyServices }, dispatch, true);
    dispatch({
      type: ActionType.SET_GRID_REQUEST_PARAMS,
      payload: {
        ...queryFilters,
      },
    });
  }, [queryFilters, dispatch, showMyServices]);

  useEffect(() => {
    updateParentStyle('0px');
    dispatch({ type: ActionType.SET_LOADING, payload: true });
    userDispatch({
      type: ActionType.SET_APP_FORM_TYPE,
      payload: IAppFormType.INSPECTION,
    });
  }, [dispatch, userDispatch]);

  useEffect(() => {
    const countryCode = localStorage.getItem('countryCode') || undefined;
    fetchAgents(countryCode);
  }, [countryCode]);

  const handleAddNote = useCallback(
    async (eventId: number, comment: string) => {
      const newComment = {
        eventId: eventId,
        userId: Number(localStorage.getItem('userId')),
        commentText: comment,
      };

      return saveNote(eventId, newComment);
    },
    [],
  );

  const handleNoteClicked = useCallback(
    async (event: Event): Promise<void> => {
      const currentEvent = {
        ...selectedEventNote,
        ...event,
        notes: await getNotes(event.eventId),
      };
      setSelectedEventNote(currentEvent);
    },
    [selectedEventNote],
  );

  useEffect(() => {
    let ref: NodeJS.Timeout;
    if (selectedEventNote && isSURAEvent) {
      ref = setInterval(
        (event) => {
          handleNoteClicked(event);
        },
        5000,
        selectedEventNote,
      );
    }

    return () => {
      updateParentStyle('50px');
      clearInterval(ref);
    };
  }, [selectedEventNote, handleNoteClicked, isSURAEvent]);

  if (searchQuery || searchQuery === null) {
    return (
      <Redirect
        to={{ pathname: '/events-claims', search: searchQuery || '' }}
      />
    );
  }

  const handleEventView = (row: any, event: any) => {
    row.stopPropagation();
    if (
      !row.target.classList.contains('MuiButtonBase-root') &&
      !row.target.classList.contains('MuiSvgIcon-root') &&
      row.target.classList?.length > 0
    ) {
      if (event?.enableClaimsTracker && event?.claimantType) {
        //Clean storage variables
        sessionStorage.removeItem('currentDetailId');
        sessionStorage.removeItem('currentStep');
        dispatch({
          type: ActionType.SET_CURRENT_INSPECTION,
          payload: null,
        });

        window.open(`/claims/${event.uid}?force=1`, '_blank');
      } else {
        history.push(`/inspections/${event.eventId}`);
      }
    }
  };

  const handleCloseNotesModal = () => {
    setIsNoteVisible(false);
    setSelectedEventNote(null);
  };

  const getAvailableFilterStatus = () => {
    const filterPermission = userActionsPermissions(ResourceAction.USE_FILTERS);
    if (filterPermission) {
      const newStatus: any = [];
      Array.from(filterPermission).forEach((p: any) => {
        const status = Object.values(EVENT_STATUSES).filter(
          (f) => f.id === +p,
        )[0];
        newStatus.push(status);
      });

      return newStatus;
    } else {
      return Object.values(EVENT_STATUSES);
    }
  };

  const getStatusIndicatorAmount = (
    id?: number,
    statusName?: string,
  ): number => {
    //Bug CLAIM-1448 : Provitional Solution for Bug while Team analize the Problem
    const isValidPanamaCode = countryCode && countryCode === CountryCode.PAN;
    if (id === 3 && isValidPanamaCode) {
      id--;
    }

    const status =
      statusName ||
      getAvailableFilterStatus().find((e: any) => e.id === id)?.value;
    if (id || statusName) {
      const _currentStatus = inspectionState.statusesIndicators.find(
        (indicator: StatusIndicator) => indicator.statusName === status,
      );

      return _currentStatus ? _currentStatus.currentRows : 0;
    } else {
      let total = 0;
      inspectionState.statusesIndicators
        .filter((i) => i.statusName !== 'AjusteExpress')
        .forEach((i: StatusIndicator) => (total += i.currentRows));

      return total;
    }
  };

  const setIntegrationConfirm = (): void => {
    dispatch({
      type: ActionType.SET_INTEGRATION_CONFIRM_RESEND,
      payload: false,
    });

    dispatch({
      type: ActionType.SET_INTEGRATION_RESEND_ID_EVENT,
      payload: -1,
    });
  };

  const resendEventToIntegration = async (): Promise<void> => {
    await resendIntegration(inspectionState.integrationResendEventId, dispatch);
  };

  const transferWithoutEstimateCSM = async (): Promise<void> => {
    await transferEstimateCSM(queryFilters, showMyServices, dispatch);
  };

  return (
    <>
      <Loader isActive={inspectionState.loading} />
      <div className="eventStatuses-claim">
        {!isMobile &&
          ![Rol.CUSTOMER].includes(Number(localStorage.getItem('rolId'))) && (
            <DashboardGrid />
          )}
      </div>
      <Box>
        <h2 className={'gridCard-claim__title'}>Lista de Casos</h2>
      </Box>
      <Box className="filterSection">
        {expressAdjust && company?.id === BrandsId.CSM && (
          <div>
            <Button
              style={{ float: 'right', marginTop: '-35px' }}
              variant="contained"
              onClick={transferWithoutEstimateCSM}
            >
              Transferir sin Estimar
            </Button>
          </div>
        )}

        <GridFilters
          company={company}
          inspector={inspector}
          agent={agent}
          state={state}
          tag={tag}
          aapIndicator={aapIndicator}
          startDate={
            queryFilters?.initialDate
              ? new Date(queryFilters?.initialDate)
              : null
          }
          endDate={
            queryFilters?.finalDate ? new Date(queryFilters?.finalDate) : null
          }
          stageSelected={stageIdFilter}
          expressAdjust={expressAdjust}
          onChangeStartDate={(date) => {
            setLoading(true, dispatch);
            setQueryFilters({ page: 0, initialDate: date || undefined });
          }}
          onChangeEndDate={(date) => {
            setLoading(true, dispatch);
            setQueryFilters({
              page: 0,
              finalDate: !date ? undefined : addDays(date, 1),
            });
          }}
          onChangeDateRange={([start, end]) => {
            setLoading(true, dispatch);
            const maxEndDate = addMonths(start, 2);
            setQueryFilters({
              page: 0,
              initialDate: start,
              finalDate: end > maxEndDate ? maxEndDate : end,
            });
          }}
          onChangeCompany={(option) => {
            setLoading(true, dispatch);
            setQueryFilters({ page: 0, companyId: option?.id });
            setCompany(option);
          }}
          onChangeInspector={(option) => {
            setLoading(true, dispatch);
            setQueryFilters({ page: 0, inspectorId: option?.id });
            setInspector(option);
          }}
          onChangeTag={(option) => {
            setLoading(true, dispatch);
            setQueryFilters({ page: 0, tagId: option?.id });
            setTag(option);
          }}
          onChangeExpressAdjust={(value: boolean | null) => {
            setLoading(true, dispatch);
            setQueryFilters({
              page: 0,
              filterByExpressAdjust: value,
              statusId: undefined,
              filterBySubrogation: undefined,
              filterByTotalLoss: undefined,
            });
            setExpressAdjust(value);
          }}
          onChangeAapIndicator={(value: boolean | null) => {
            setLoading(true, dispatch);
            setQueryFilters({
              page: 0,
              filterByAAP: value,
            });
            setAapIndicator(value);
          }}
          setCurrentInspector={(inspectors) => {
            const option =
              inspectors.find((i) => i.id === queryFilters.inspectorId) || null;

            setInspector(option);
          }}
          onChangeAgent={(option) => {
            setLoading(true, dispatch);
            setQueryFilters({ page: 0, logAgentId: option?.id });
            setInspector(option);
          }}
          onChangeState={(option) => {
            setLoading(true, dispatch);
            setQueryFilters({
              page: 0,
              statusId: option?.id,
              filterByTotalLoss:
                option?.id === FilterState.FILTER_TOTAL_LOSS || undefined,
              filterBySubrogation:
                option?.id === FilterState.FILTER_SUBROGATION || undefined,
            });
          }}
          setCurrentAgent={(agents) => {
            const option =
              agents.find((i) => i.id === queryFilters.logAgentId) || null;
            setAgent(option);
          }}
          setCurrentState={(states) => {
            const option =
              states.find((i) => i.id === queryFilters.statusId) || null;
            setState(option);
          }}
          setCurrentCompany={(companies) => {
            const option =
              companies.find((c) => c.id === queryFilters.companyId) || null;
            setCompany(option);
          }}
          setCurrentTag={(tags) => {
            const option =
              tags.find((t) => t.id === queryFilters.tagId) || null;
            setTag(option);
          }}
          onServicesCheckChange={setShowMyServices}
          onChangeStage={(value: any) => {
            setLoading(true, dispatch);

            setQueryFilters({
              page: 0,
              filterByExpressAdjust: undefined,
              filterBySubrogation: undefined,
              filterByTotalLoss: undefined,
              statusId: value === queryFilters.statusId ? undefined : value,
            });

            setStageIdFilter(value);
          }}
          showMyServices={showMyServices}
          currentUser={currentUser}
        />
      </Box>
      <Card className="gridCard-claim">
        <NotesModal
          showNotes
          visible={isNoteVisible}
          event={selectedEventNote}
          onAddNote={handleAddNote}
          onRefresh={handleNoteClicked}
          onClose={handleCloseNotesModal}
          inspectorName={''}
        />
        <CardContent className="card-content">
          {inspectionState.inspections &&
            inspectionState.inspections.length > 0 && (
              <EventsTable
                events={inspectionState.inspections}
                paginator={inspectionState.paginator}
                totalElements={getStatusIndicatorAmount(
                  currentStatus,
                  expressAdjust ? 'AjusteExpress' : undefined,
                )}
                page={queryFilters.page || 0}
                onClickEditView={handleEventView}
                onClickNote={handleNoteClicked}
                onChangePage={(event: unknown, newPage: number) =>
                  setQueryFilters({ page: newPage })
                }
                reloadEvents={() =>
                  getInspections(
                    { ...queryFilters, showMyServices },
                    dispatch,
                    true,
                  )
                }
              />
            )}
          {!inspectionState.loading &&
            inspectionState.inspections &&
            inspectionState.inspections.length === 0 && <SearchError />}
        </CardContent>
      </Card>
      <Dialog
        open={inspectionState.integrationResendConfirm}
        onClose={() => setIntegrationConfirm()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Desea ejecutar un reenvío hacia la integración?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setIntegrationConfirm()}
            color="secondary"
          >
            No
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => resendEventToIntegration()}
            color="primary"
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
