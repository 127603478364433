import './styles.scss';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, Button, Autocomplete, TextField } from '@mui/material';
import { format } from 'date-fns';
import style from 'override-mui-styles';
import clsx from 'clsx';
import {
  downloadReport,
  downloadReportClaim,
  InspectionContext,
} from 'context/inspections';
import DatePicker from 'components/common/datepicker/DatePicker';
import Loader from 'components/common/loader/Loader';
import COMPANIES from 'static/constants/companies';
import { validateDateRange } from 'shared/utils';
import { ActionType } from 'types/action';

interface EventReportProps {
  report: string;
}

export default function EventReport(props: EventReportProps): JSX.Element {
  const classes = style();
  const { report } = props;
  const { dispatch } = useContext(InspectionContext);
  const [downloadURL, setDownloadURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(format(new Date(), 'MM/dd/yyyy')),
  );
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(format(new Date(), 'MM/dd/yyyy')),
  );
  const [filterCompanyId, setFilterCompanyId] = useState<number>(-1);
  const [listCompanies, setListCompanies] = useState<any[]>([]);

  const getCompanies = useCallback(() => {
    const filteredCompanies = COMPANIES(true, true).filter(
      (f) =>
        (f.type === 'INSURANCE' || f.type === 'INTERNAL') &&
        f.code === localStorage.getItem('countryCode'),
    );

    return filteredCompanies;
  }, []);

  const onChangeStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const onChangeEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const isPreInspectionAdmin = (): boolean => {
    return Number(localStorage.getItem('rolId')) === 6;
  };

  const generateReport = () => {
    if (startDate && endDate) {
      const adminCompanies = [3, 13, 14]; // Connect CRC, PA, PRI
      const roleId = localStorage.getItem('rolId') ?? '1';
      let companyId = localStorage.getItem('companyId') ?? filterCompanyId;

      setDownloadURL('');

      if (isPreInspectionAdmin()) {
        if (filterCompanyId === -1) {
          dispatch({
            type: ActionType.SET_GENERAL_ALERT,
            payload: {
              showAlert: true,
              alertMessage: 'Por favor seleccione una aseguradora.',
              alertType: 'warning',
            },
          });

          return;
        } else {
          companyId = filterCompanyId.toString();
        }
      }

      setFilterCompanyId(-1);

      (async (): Promise<void> => {
        setLoading(true);
        if (validateDateRange(startDate, endDate)) {
          let result;
          const request = {
            countryCode: localStorage.getItem('countryCode'),
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
            inicio: format(startDate, 'yyyy-MM-dd') + ' 00:00:00',
            fin: format(endDate, 'yyyy-MM-dd') + ' 23:59:59',
            companyId: adminCompanies.includes(Number(companyId))
              ? -1
              : Number(companyId),
            rolId: Number(roleId),
            customerCode: localStorage.getItem('customerCode'),
          };
          switch (report) {
            case 'claims': {
              result = await downloadReportClaim(request, dispatch);
              break;
            }
            case 'event': {
              result = await downloadReport(request, dispatch);
              break;
            }
          }

          if (result === 'Fail') {
            dispatch({
              type: ActionType.SET_GENERAL_ALERT,
              payload: {
                showAlert: true,
                alertMessage:
                  'No se encontraron resultados en el rango seleccionado.',
                alertType: 'warning',
              },
            });
          } else {
            setDownloadURL(result);
          }
        } else {
          dispatch({
            type: ActionType.SET_GENERAL_ALERT,
            payload: {
              showAlert: true,
              alertMessage:
                'Por favor seleccione un rango de fechas <b>menor o igual a 1 mes</b>.',
              alertType: 'warning',
            },
          });
        }

        setTimeout(() => setLoading(false), 3000);
      })();
    }
  };

  const downloadFile = () => {
    setLoading(true);
    saveAs(downloadURL, 'reporteSIC');
    setTimeout(() => setLoading(false), 3000);
  };

  useEffect(() => {
    if (Array.from(listCompanies).length === 0) {
      setListCompanies(getCompanies());
    }
  }, []);

  return (
    <>
      <Loader isActive={loading} />
      <div className="reportForm">
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <label className="reportForm__label">Fecha Desde:</label>
            <br />
            <DatePicker
              startDate={startDate}
              onChangeStartDate={(date) => onChangeStartDate(date)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <label className="reportForm__label">Fecha Hasta:</label>
            <br />
            <DatePicker
              startDate={endDate}
              onChangeStartDate={(date) => onChangeEndDate(date)}
            />
          </Grid>
          {isPreInspectionAdmin() && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <label className="reportForm__label">Aseguradora:</label>
              <br />
              <Autocomplete
                options={listCompanies}
                getOptionLabel={(option: Option) => option.name}
                style={{ width: 300 }}
                onChange={(ev, option: Option | null) => {
                  if (option.id !== null) {
                    setFilterCompanyId(option.id);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Aseguradora"
                    classes={{ root: classes.inputRoot }}
                  />
                )}
              />
            </Grid>
          )}

          <Grid
            className="generateSection"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item>
              <Button
                id="continue-btn"
                variant="contained"
                color="secondary"
                className={clsx(classes.button)}
                size="large"
                onClick={() => generateReport()}
              >
                Generar Reporte
              </Button>
              {downloadURL?.length > 0 && (
                <>
                  &nbsp;&nbsp;
                  <Button
                    id="continue-btn"
                    variant="contained"
                    className={clsx(classes.button)}
                    size="large"
                    onClick={() => downloadFile()}
                  >
                    Descargar Resultados
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
