import { ButtonGroup, Grid, Link, TextField, Checkbox } from '@mui/material';
import { Button as ButtonUI } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import DialogComponent from 'components/common/dialog';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import './index.scss';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import { formatNumber, formatNumberPoint } from 'shared/utils';
import { ActionType } from 'types/action';
import Button from '../button';
import DataTable from '../grid';
import BreakdownTotalLoss from '../breakdownTotalLoss';
import { useTotalLossAdjust } from 'context/adjust/totalLoss-adjust-context';
import { IClaimForm } from 'types/claimsTracker';

export interface AdjustAmounts {
  estimatedTotalAmount: string;
  porcDepreciation: string;
  percNegligence: string;
  depreciationAmount: string;
  deductibleAmount: string;
  advancePayment: string;
  totalInsuranceAmount: string;
  customerResponsabilityAmount: string;
  fines: string;
  missingParts: string;
  negligence: string;
  constructive: string;
  others: string;
  trailer: string;
  othersPlus: string;
  totalToPay: string;
  isTotalLoss: boolean;
  closedWithoutPayment: boolean;
  payToBank: boolean;
}

interface propsAdjust {
  totalEstimated: string;
  subtotalEstimated: string;
  claimUId?: string;
  disabled: boolean;
  claimForm?: IClaimForm;
}

export const calculateDepreciation = (
  porcDep: number,
  totalEstimated: number,
): number => {
  if (porcDep > 0 && porcDep <= 100) {
    return (totalEstimated * porcDep) / 100;
  }

  return 0;
};

export const calculateNegligence = (
  porcNeg: number,
  totalEstimated: number,
): number => {
  if (porcNeg >= 0 && porcNeg <= 100) {
    return (totalEstimated * porcNeg) / 100;
  }

  return totalEstimated;
};

export const calculateTotalInsuranceAmount = (
  totalEstimated: number,
  depreciation: number,
  deductibleAmount: number,
  advancePayment: number,
  percNegligence: number,
): number => {
  let totalInsuranceAmount = totalEstimated - depreciation;
  totalInsuranceAmount -= (totalInsuranceAmount * percNegligence) / 100;

  totalInsuranceAmount -= deductibleAmount;
  totalInsuranceAmount -= advancePayment;

  return totalInsuranceAmount < 0 ? 0 : totalInsuranceAmount;
};

export const calculateCustomerResponsabilityAmount = (
  deductibleAmount: number,
  depreciation: number,
  negligence: number,
): number => {
  return deductibleAmount + depreciation + negligence;
};

const ClaimAdjust: FunctionComponent<propsAdjust> = ({
  totalEstimated,
  subtotalEstimated,
  claimUId,
  disabled,
  claimForm,
}): JSX.Element => {
  const [loadedAmounts, setLoadedAmounts] = useState(false);

  useEffect(() => {
    getAllAdjustAmount().catch(console.error);
    settingTladjust({
      ...tladjust,
      IsTotalLoss: claimForm?.info?.totalLoss?.isApply,
    });
  }, [claimForm?.info?.case]);

  useEffect(() => {
    if (totalEstimated && subtotalEstimated && loadedAmounts) {
      setTotalEstimatedAmount(totalEstimated);
      const data = { ...amountsState };
      calculateAdjust(data);
    }
  }, [subtotalEstimated, totalEstimated, loadedAmounts]);

  const { dispatch } = useContext<IInspectionContext>(InspectionContext);
  const [dataHistoric, setDataHistoric] = useState<any>([]);
  const [totalEstimatedAmount, setTotalEstimatedAmount] = useState(
    totalEstimated,
  );
  const [amountsState, setAmountsState] = useState<AdjustAmounts>({
    estimatedTotalAmount: '',
    porcDepreciation: '0',
    percNegligence: '0',
    depreciationAmount: '',
    deductibleAmount: '0',
    advancePayment: '',
    totalInsuranceAmount: '',
    customerResponsabilityAmount: '',
    constructive: '0',
    fines: '0',
    missingParts: '0',
    negligence: '0',
    others: '0',
    othersPlus: '0',
    totalToPay: '0',
    trailer: '0',
    isTotalLoss: false,
    closedWithoutPayment: false,
    payToBank: false,
  });
  const { tladjust, settingTladjust } = useTotalLossAdjust();
  const [selectionFields, setSelectionFields] = useState<GridRowId[]>([]);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [totalLossAdjustSelected, setTotalLossAdjustSelected] = useState(
    claimForm?.info?.totalLoss?.isApply,
  );
  const [isTotalLoss, setisTotalLoss] = useState(
    claimForm?.info?.totalLoss?.isApply,
  );

  const columns: GridColDef[] = [
    { field: 'totalInsuranceAmount', headerName: 'Monto Total', width: 100 },
    { field: 'createdDate', headerName: 'Fecha', width: 180 },
    { field: 'userName', headerName: 'Usuario', width: 100 },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 100,
      renderCell: (params) => (
        <Link
          className="claim-estimated__table--link"
          href="#"
          onClick={() => loadAdjust(params.id)}
        >
          Ver detalle
        </Link>
      ),
    },
    {
      field: 'fileUrl',
      headerName: 'Documentos',
      width: 150,
      renderCell: (params) => (
        <Link
          className="claim-adjust__table--link"
          target="_blank"
          href={params.value}
          hidden={!params.value}
        >
          Ver documento
        </Link>
      ),
    },
  ];

  useEffect(() => {
    setisTotalLoss(
      tladjust?.IsTotalLoss == undefined
        ? claimForm?.info?.totalLoss?.isApply
        : tladjust?.IsTotalLoss,
    );
  }, [tladjust?.IsTotalLoss]);

  const loadAdjust = (id: any): void => {
    const register = dataHistoric.find((x) => x.id === id);
    setTotalEstimatedAmount(register?.estimatedTotalAmount);
    setAmountsState(register);
  };

  const handleCancel = (): void => {
    setSelectionFields([]);
  };

  const openConfirmDialog = (): void => {
    setOpenDialogConfirm(true);
  };

  const handleConfirm = async (): Promise<void> => {
    setOpenDialogConfirm(false);
    await handleDelete();
  };
  const handleDelete = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      for (const id of selectionFields) {
        await new ClaimsTrackerClient().DeleteAdjustAmount(id as number);
      }

      setSelectionFields([]);
      await getAllAdjustAmount();

      dispatch({ type: ActionType.SET_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const getAllAdjustAmount = async (): Promise<void> => {
    try {
      dispatch({
        type: ActionType.SET_ADJUST_AMOUNT,
        payload: undefined,
      });
      const result = await new ClaimsTrackerClient().getAllAdjustAmount(
        claimUId,
        false,
      );

      for (let i = 0; i < result.length; i++) {
        result[i].totalInsuranceAmount = formatNumberPoint(
          result[i].totalInsuranceAmount,
        );
        result[i].estimatedTotalAmount = formatNumberPoint(
          result[i].estimatedTotalAmount,
        );
        result[i].deductibleAmount = formatNumberPoint(
          result[i].deductibleAmount,
        );
        result[i].advancePayment = formatNumberPoint(result[i].advancePayment);
        result[i].customerResponsabilityAmount = formatNumberPoint(
          result[i].customerResponsabilityAmount,
        );
      }
      if (result?.length > 0) {
        const data = result.slice(-1)[0];

        data.closedWithoutPayment = false;

        dispatch({
          type: ActionType.SET_ADJUST_AMOUNT,
          payload: '1',
        });

        setAmountsState({ ...data });
        calculateAdjust({ ...data });
      }

      setLoadedAmounts(true);
      setDataHistoric(result);
    } catch (e) {
      console.error(e);
    }
  };

  const onClickSave = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });
      const state = amountsState;
      state.estimatedTotalAmount = totalEstimatedAmount;
      state.payToBank = state.payToBank === null ? false : state.payToBank;
      setAmountsState(state);
      const result = await new ClaimsTrackerClient().SaveAdjustAmount(
        amountsState,
        claimUId,
      );

      if (result?.data?.success) {
        setAmountsState({
          estimatedTotalAmount: '',
          porcDepreciation: '0',
          percNegligence: '0',
          depreciationAmount: '',
          deductibleAmount: '0',
          advancePayment: '',
          totalInsuranceAmount: '',
          customerResponsabilityAmount: '',
          constructive: '0',
          fines: '0',
          missingParts: '0',
          negligence: '0',
          others: '0',
          othersPlus: '0',
          totalToPay: '0',
          trailer: '0',
          isTotalLoss: false,
          closedWithoutPayment: false,
          payToBank: false,
        });

        await getAllAdjustAmount();
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    } catch (e) {
      console.warn(e);
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const onChangeClosedWithoutPayment = (event: any): void => {
    const data = amountsState;
    data.closedWithoutPayment = event?.target?.checked;
    setAmountsState({ ...data });
  };
  const onChangePayToBank = (event: any): void => {
    const data = amountsState;
    data.payToBank = event?.target?.checked;
    setAmountsState({ ...data });
  };
  const onCalcAdjust = (event: any): void => {
    if (amountsState) {
      let changeEstimated = false;
      const data = amountsState;
      data[event?.target?.name] = event?.target?.value.replace(/[^0-9.]/g, '');

      if (event?.target?.name === 'estimatedTotalAmount') {
        setTotalEstimatedAmount(event?.target?.value.replace(/[^0-9.]/g, ''));
        changeEstimated = true;
      }

      setAmountsState({ ...data });
      calculateAdjust(data, changeEstimated);
    }
  };

  const validateAmounts = (): boolean => {
    if (isTotalLoss) {
      return true;
    }
    if (disabled) {
      return true;
    }
    if (
      !amountsState.deductibleAmount &&
      claimForm?.info.type === 'Asegurado'
    ) {
      return true;
    }

    if (!amountsState.advancePayment) {
      return true;
    }

    if (!amountsState.porcDepreciation) {
      return true;
    }

    if (
      !amountsState.percNegligence &&
      claimForm?.info.type === 'Perjudicado'
    ) {
      return true;
    }

    return false;
  };

  const calculateAdjust = (
    data: AdjustAmounts,
    changeEstimated = false,
  ): void => {
    const porcDep = Number(data.porcDepreciation ?? 0);
    const estimatedTotal = Number(
      changeEstimated
        ? data.estimatedTotalAmount ?? 0
        : totalEstimatedAmount
        ? totalEstimatedAmount
        : totalEstimated ?? 0,
    );

    const estimatedSubtotal = Number(subtotalEstimated ?? 0);
    const deductibleAmount = Number(data.deductibleAmount ?? 0);
    const advancePayment = Number(data.advancePayment ?? 0);
    const percNegligence = Number(data.percNegligence ?? 0);

    const depreciation = calculateDepreciation(porcDep, estimatedSubtotal);
    const negligence = calculateNegligence(
      percNegligence,
      estimatedTotal - depreciation,
    );

    const totalInsuranceAmount = calculateTotalInsuranceAmount(
      estimatedTotal,
      depreciation,
      deductibleAmount,
      advancePayment,
      percNegligence,
    );

    const customerResponsabilityAmount = calculateCustomerResponsabilityAmount(
      deductibleAmount,
      depreciation,
      negligence,
    );

    data.depreciationAmount = depreciation.toFixed(2);
    data.negligence = negligence.toFixed(2);
    data.totalInsuranceAmount = totalInsuranceAmount.toFixed(2);
    data.customerResponsabilityAmount = customerResponsabilityAmount.toFixed(2);

    setAmountsState({ ...data });
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={6} className="claim-adjust">
        {isTotalLoss ? (
          <ButtonGroup
            size="large"
            variant="text"
            aria-label="text button group"
            className="claim-adjust-menu"
          >
            <ButtonUI
              className="claim-adjust-menu-option"
              style={{
                fontWeight: totalLossAdjustSelected ? 'normal' : 'bold',
              }}
              onClick={() => setTotalLossAdjustSelected(false)}
            >
              AJUSTE
            </ButtonUI>
            <ButtonUI
              className="claim-adjust-menu-option"
              style={{
                fontWeight: totalLossAdjustSelected ? 'bold' : 'normal',
              }}
              onClick={() => {
                setTotalLossAdjustSelected(true);
                console.warn(tladjust);
              }}
            >
              AJUSTE PÉRDIDA TOTAL
            </ButtonUI>
          </ButtonGroup>
        ) : null}

        {totalLossAdjustSelected && isTotalLoss ? (
          <Grid item xs={12} sm={6} md={6} className="claim-adjust-totalLoss">
            <BreakdownTotalLoss
              claimUId={claimUId}
              totalEstimated={(tladjust?.ActualCost ?? '0').replace(',', '')}
              visible={true}
              claimForm={claimForm}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={6} className="claim-adjust-normal">
            <Grid item xs={12} sm={6} md={6} className="claim-adjust__content">
              <Grid className="claim-adjust__data">
                <Grid>
                  <Grid className="claim-adjust__data--title">
                    <p>AJUSTE</p>
                  </Grid>
                  <Grid className="claim-adjust__data--row">
                    <p
                      style={{ marginRight: '6px' }}
                      className="claim-adjust__data--icon"
                    ></p>
                    <TextField
                      disabled={disabled}
                      name="estimatedTotalAmount"
                      className="claim-adjust__data--row--input"
                      label="Total Estimado"
                      value={formatNumber(totalEstimatedAmount)}
                      onChange={onCalcAdjust}
                    ></TextField>
                  </Grid>
                  <Grid className="claim-adjust__data--dep">
                    <Grid className="claim-adjust__data">
                      <p className="claim-adjust__data--icon">-</p>
                      <TextField
                        disabled={disabled}
                        name="porcDepreciation"
                        className="claim-adjust__data--dep--porc"
                        label="% Dep *"
                        value={formatNumber(amountsState.porcDepreciation)}
                        onChange={onCalcAdjust}
                      ></TextField>
                    </Grid>
                    <Grid>
                      <TextField
                        disabled
                        className="claim-adjust__data--dep--amount"
                        label="Depreciación"
                        value={formatNumber(amountsState.depreciationAmount)}
                      ></TextField>
                    </Grid>
                  </Grid>
                  {claimForm?.info.type === 'Perjudicado' ? (
                    <Grid className="claim-adjust__data--dep">
                      <Grid className="claim-adjust__data">
                        <p className="claim-adjust__data--icon">-</p>
                        <TextField
                          disabled={disabled}
                          name="percNegligence"
                          className="claim-adjust__data--dep--porc"
                          label="% Neg *"
                          value={formatNumber(amountsState.percNegligence)}
                          onChange={onCalcAdjust}
                        ></TextField>
                      </Grid>
                      <Grid>
                        <TextField
                          disabled
                          className="claim-adjust__data--dep--amount"
                          label="Negligencia"
                          value={formatNumber(amountsState.negligence)}
                        ></TextField>
                      </Grid>
                    </Grid>
                  ) : null}
                  {claimForm?.info.type === 'Asegurado' ? (
                    <Grid className="claim-adjust__data--row">
                      <p className="claim-adjust__data--icon">-</p>
                      <TextField
                        disabled={disabled}
                        name="deductibleAmount"
                        className="claim-adjust__data--row--input"
                        label="Deducible *"
                        onChange={onCalcAdjust}
                        value={formatNumber(amountsState.deductibleAmount)}
                      ></TextField>
                    </Grid>
                  ) : null}
                  <Grid className="claim-adjust__data--dep">
                    <Grid className="claim-adjust__data">
                      <Checkbox
                        checked={amountsState.closedWithoutPayment}
                        onChange={onChangeClosedWithoutPayment}
                      />
                      <label className="claim-adjust__label">
                        Cierre sin Pago
                      </label>
                    </Grid>
                  </Grid>
                  <Grid className="claim-adjust__data--row">
                    <p className="claim-adjust__data--icon">-</p>
                    <TextField
                      disabled={disabled}
                      name="advancePayment"
                      className="claim-adjust__data--row--input"
                      label="Pago previo *"
                      onChange={onCalcAdjust}
                      value={formatNumber(amountsState.advancePayment)}
                    ></TextField>
                  </Grid>
                  <Grid>
                    <div className="claim-adjust__separator"></div>
                  </Grid>
                  <Grid className="claim-adjust__data--row">
                    <p className="claim-adjust__data--icon">=</p>
                    <TextField
                      name="totalInsuranceAmount"
                      disabled
                      className="claim-adjust__data--row--input"
                      label="Monto total del seguro"
                      value={formatNumber(amountsState.totalInsuranceAmount)}
                    ></TextField>
                  </Grid>
                  <Grid className="claim-adjust__data--row">
                    <p className="claim-adjust__data--icon">=</p>
                    <TextField
                      disabled
                      name="customerResponsabilityAmount"
                      className="claim-adjust__data--row--input"
                      label="Responsabilidad del cliente"
                      value={formatNumber(
                        amountsState.customerResponsabilityAmount,
                      )}
                    ></TextField>
                  </Grid>
                  <Grid className="claim-adjust__data--dep">
                    <Grid className="claim-adjust__data">
                      <Checkbox
                        checked={amountsState.payToBank}
                        onChange={onChangePayToBank}
                      />
                      <label className="claim-adjust__label">
                        Pagar al Banco
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {dataHistoric?.length > 0 && (
                <Grid className="claim-adjust__historic">
                  <Grid>
                    <p>HISTÓRICO DE AJUSTES</p>
                  </Grid>
                  <Grid className="claim-adjust__table">
                    {selectionFields.length > 0 && (
                      <Grid className="claim-adjust__table--options">
                        <span>{selectionFields.length} items selected</span>
                        <Grid className="claim-adjust__table--options--actions">
                          <Button
                            text="Eliminar"
                            variant="text"
                            style={{ color: 'white', fontSize: 14 }}
                            onClick={openConfirmDialog}
                          ></Button>
                          <span>|</span>
                          <Button
                            text="Cancel"
                            variant="text"
                            style={{ color: 'white', fontSize: 14 }}
                            onClick={handleCancel}
                          ></Button>
                        </Grid>
                      </Grid>
                    )}
                    <DataTable
                      columns={columns}
                      data={dataHistoric}
                      showCheckBox={!disabled}
                      selectionModel={selectionFields}
                      onRowSelectionModelChange={(rows: any): void =>
                        setSelectionFields(rows)
                      }
                    ></DataTable>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid className="claim-adjust__footer">
              <Button
                disabled={validateAmounts()}
                text="Guardar Ajuste"
                onClick={onClickSave}
              />
            </Grid>
          </Grid>
        )}

        <DialogComponent
          messageStyle={{ fontSize: '16px' }}
          message={`¿Desea eliminar los ${selectionFields.length} registros seleccionados?`}
          open={openDialogConfirm}
          handleClose={() => setOpenDialogConfirm(false)}
          handleConfirm={handleConfirm}
        />
      </Grid>
    </>
  );
};

export default ClaimAdjust;
